<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageEditionSondage"
  >
    <base-material-card icon="mdi-chart-box-outline">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t('sondages_page.sondage') }}
        </div>
      </template>

      <v-form>
        <v-row justify="center">
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="sondage.nom"
              data-cy="tfNom"
              class="purple-input"
              :label="$t('general.nom')"
            />

            {{ $t('general.description') }} :
            <cnx-editeur
              data-cy="ckeDescription"
              :texte.sync="sondage.description"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="mr-0"
            data-cy="btnSauvegarder"
            @click="onSauvegarder()"
          >
            {{ $t('general.sauvegarder') }}
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card icon="mdi-help-circle-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('sondages_page.les_questions') }}
            </div>
          </template>
          <v-data-table
            :items="sondage.listeQuestions"
            :headers="questionHeaders"
            data-cy="dtQuestions"
            :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
          >
            <template v-slot:[`item.texte`]="{ item }">
              <div v-html="item.texte" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="px-2 ml-1 primary"
                min-width="0"
                small
                @click="onEditerQuestion(item)"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 warning"
                min-width="0"
                small
                @click="onSupprimerQuestion(item)"
              >
                <v-icon
                  small
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-btn
            data-cy="btnAjouterQuestion"
            class="primary"
            @click="onAjouterQuestion()"
          >
            <v-icon left>
              mdi-plus-circle
            </v-icon>
             {{ $t('sondages_page.ajouter_question') }}
          </v-btn>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          v-if="editionQuestion"
          outline
        >
          <v-card-text>
            <div class="caption mb-4">
               {{ $t('sondages_page.text_question') }}
            </div>
            <div class="zoneTexte">
              <cnx-editeur
                :texte.sync="editionQuestion.texte"
                data-cy="ckeQuestion"
                @blur="onSauvegarderQuestion(editionQuestion)"
              />
            </div>
            <div class="caption pt-8">
               {{ $t('sondages_page.choix_de_reponses') }}
            </div>
            <v-card
              v-for="choix in editionQuestion.listeChoix"
              :key="choix.id"
              outlined
            >
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <div class="zoneTexte">
                      <!-- On n'arrive pas à bien rafraichir les infos -->
                      <cnx-editeur
                        :texte.sync="choix.texte"
                        data-cy="ckeChoix"
                        @blur="onSauvegarderChoix(choix)"
                      />
                    <!-- <v-textarea
                      v-model="choix.texte"
                      @blur="onSauvegarderChoix(choix)"
                    /> -->
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="choix.points"
                      data-cy="tfPoints"
                      label="Points"
                      type="number"
                      @blur="onSauvegarderChoix(choix)"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="px-2 ml-1 warning"
                      min-width="0"
                      small
                      @click="onSupprimerChoix(choix)"
                    >
                      <v-icon
                        small
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn
              color="primary"
              data-cy="btnAjouterChoix"
              @click="onAjouterChoix()"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              {{ $t('sondages_page.ajouter_un_choix') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  .ck-editor__editable {
    min-height: 100px;
   }
   .zoneTexte {
   -moz-box-shadow:    inset 0 0 3px #7c7c7c;
   -webkit-box-shadow: inset 0 0 3px #7c7c7c;
   box-shadow:         inset 0 0 3px #7c7c7c;
}
</style>

<script>

  import restApiService from '@/services/restApiService.js'
  import CnxEditeur from '../../composantes/CnxEditeur.vue'
  import i18n from '@/i18n.js'

  export default {
    components: {
      CnxEditeur,
    },
    data: () => ({
      sondage: {
        compteClient: {
          id: undefined,
        },
      },
      editionQuestion: undefined,
      questionHeaders: [
        { text: i18n.t('question'), value: 'texte' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
    }),
    mounted () {
      this.chargerSondage(this.$route.params.idSondage).then(() =>
        this.onEditerQuestion(this.sondage.listeQuestions[0]),
      )
    },
    methods: {
      chargerSondage (idSondage) {
        return restApiService
          .get(`/api/sondages/${idSondage}`)
          .then((result) => {
            this.sondage = result.data
          })
          .catch((erreur) => alert(erreur))
      },
      chargerQuestions (idSondage) {
        return restApiService
          .get(`/api/sondages/${idSondage}`)
          .then((result) => {
            this.sondage.listeQuestions = result.data.listeQuestions
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarder () {
        restApiService
          .put('/api/sondages', this.sondage)
          .then(() => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => alert(erreur))
      },
      onEditerQuestion (item) {
        this.editionQuestion = item
      },
      onAjouterQuestion () {
        const question = {
          texte: i18n.t('sondages_page.votre_question'),
          sondage: {
            id: this.sondage.id,
          },
        }
        restApiService
          .post('/api/sondage-questions', question)
          .then((response) => {
            this.chargerQuestions(this.sondage.id).then((res) => {
              this.editionQuestion = this.sondage.listeQuestions.find(q => (q.id === response.data.id))
            })
          })
          .catch((erreur) => alert(erreur))
      },
      onAjouterChoix () {
        const choix = {
          texte: i18n.t('sondages_page.votre_choix'),
          question: {
            id: this.editionQuestion.id,
          },
        }
        restApiService
          .post('/api/sondage-choix', choix)
          .then((response) => {
            this.chargerQuestions(this.sondage.id).then(() => {
              this.editionQuestion = this.sondage.listeQuestions.find(q => (q.id === this.editionQuestion.id))
            })
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarderQuestion (question) {
        const q = {
          sondage: {
            id: this.sondage.id,
          },
          ...question,
        }
        restApiService
          .put('/api/sondage-questions', q)
          .then(() => {
            this.$dialog.notify.success(i18n.t('sondages_page.sondage_mis_a_jour'))
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarderChoix (choix) {
        const c = {
          question: {
            id: this.editionQuestion.id,
          },
          ...choix,
        }
        restApiService
          .put('/api/sondage-choix', c)
          .then(() => {
            this.$dialog.notify.success(i18n.t('sondages_page.sondage_mis_a_jour'))
          })
          .catch((erreur) => alert(erreur))
      },

      onSupprimerQuestion (item) {
        this.$dialog.confirm({
          text: i18n.t('confirmation_suppression_element'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/sondage-questions/' + item.id)
              .then((response) => {
                if (this.editionQuestion.id === item.id) {
                  this.editionQuestion = null
                }
                this.chargerQuestions(this.sondage.id)
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },
      onSupprimerChoix (item) {
        this.$dialog.confirm({
          text: i18n.t('confirmation_suppression_element'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/sondage-choix/' + item.id)
              .then((response) => {
                this.chargerQuestions(this.sondage.id).then((r) => {
                  this.editionQuestion = this.sondage.listeQuestions.find(q => (q.id === this.editionQuestion.id))
                })
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },
    },
    //
  }
</script>
